.p-contactarea {
  background-color: $keyColor;
  &__inner {
    display: flex;
    padding: 40px 0 !important;
    @media screen and (max-width:$contentWidth + $contentPadding) {
      padding: 60px $contentPadding !important;
    }
    @include mq-max(lg) {
      display: block;
      padding: 40px 15px 30px !important;
    }
  }
  &__message {
    color: $white;
    font-size: 24px;
    font-weight: 600;
    padding-right: 70px;
    @include mq-max(lg) {
      padding-right: 0;
      text-align: center;
      margin-bottom: 30px;
      position: relative;
    }
    &:after {
      @include mq-max(lg) {
        content: '';
        display: inline-block;
        width: 90%;
        height: 1px;
        border-bottom: 1px solid $white;
        position: absolute;
        left: 50%;
        bottom: -14px;
        transform: translateX(-50%);
      }
    }
  }
  &__contact {
    display: flex;
    @include mq-max(lg) {
      justify-content: center;
    }
    @include mq-max(sm) {
      display: block;
    }
  }
  &__tel {
    margin-right: 30px;
    @include mq-max(sm) {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .time {
      color: $white;
      display: flex;
      justify-content: center;
      @include mq-max(lg) {
        text-align: center;
      }
      dt {
        margin-right: 1em;
      }
      dd {
        &:not(:last-child) {
          margin-right: 1em;
        }
      }
    }
    .num {
      color: $white;
      display: block;
      font-size: 31px;
      font-weight: 600;
      position: relative;
      text-align: left;
      letter-spacing: 0.06em;
      padding-left: 40px;
      @include mq-max(lg) {
        text-align: center;
        padding-left: 40px;
      }
      &:before {
        @include fa('f095');
        position: absolute;
        top: 52%;
        left: 0;
        transform: translateY(-50%);
        transition: right $transition;
        font-size: 32px;
        @include mq-max(lg) {
          right: 250px;
        }
      }
    }
  }
  &__mail {
    text-align: center;
    .c-btn {
      font-size: 24px;
      padding: 21px 45px 21px 78px;
      background-color: $white;
      color: $keyColor;
      @include mq-max(lg) {
        font-size: 17px;
      }
      &:after {
        content: none;
      }
      &:before {
        @include fa('f0e0');
        position: absolute;
        top: 52%;
        left: 40px;
        transform: translateY(-50%);
        transition: right $transition;
        font-size: 29px;
      }
      &:hover {
        background-color: $keyColor;
        color: $white;
        border: 1px solid $white;
      }
    }
  }
}
