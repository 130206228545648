.l-header {
  background-color: transparent;
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: $headerHeight;
    margin: 0 auto;
    padding: 0 0 0 30px;
    position: relative;
    z-index: 110;
    @include mq-max($changePoint) {
      display: block;
      height: $headerHeight-sp;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 20px;
    line-height: 0;
    display: inline-block;
    width: 200px; // 状況によって変更可
    @include mq-max($changePoint) {
      width: 150px;
      margin: 0;
    }
    img {
      width: 100%;
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    position: relative;
    line-height: 0;
    @include mq-max($changePoint) {
      height: 100%;
      padding: 10px;
      background-color: $white;
      line-height: 0;
      display: flex;
      align-items: center;
    }
  }
}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  //@include mq-max($changePoint) {
    display: block;
    position: absolute;
    top: 50%;
    //right: 10px;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 111;
    span {
      display: block;
      position: absolute;
      width: 30px;
      border-bottom: solid 1px $keyColor;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 6px;
      &:nth-child(1) {
        top: 9px;
      }
      &:nth-child(2) {
        top: 18px;
        width: 20px;
      }
      &:nth-child(3) {
        top: 27px;
      }
    }
    &--sp {
      display: none;
      @include mq-max($changePoint) {
        display: block;
        right: 10px;
      }
    }
    &.is-toggleActive {
      span{
        &:nth-child(1) {
          top: 18px;
          left: 6px;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3) {
          top: 18px;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          top: 15px;
          left: 8px;
        }
      }
    }
  //}
}
