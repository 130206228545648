.l-navigation {
  //  background-color: $white;
    height: 100%;
    display: flex;

    @include mq-max($changePoint) {
      display: none;
      height: auto;
      //用途不明　不要ならそのうち削除
      // html.open &.active > ul {
      //   overflow-y: scroll;
      //   height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
      //   padding-bottom: 120px;
      // }
    }
    //用途不明　不要ならそのうち削除
    // &.active {
    //   transform: translateY(0%);
    // }

    //ハンバーガーメニューオープン時に追加されるクラス
    &.is-navOpen {}

    &--cv {
      display: flex;
      z-index: 105;
    }

    &__inner {
      padding: 0;
      display: flex;
      height: 100%;//menu.jsで指定した値が優先される
      @include mq-max($changePoint) {
        display: block;
        height: calc(100vh - #{$headerHeight-sp}); //画面高さ - fixedヘッダーの高さ
        overflow-y: scroll;
      }
    }
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      &:last-child {
        margin-right: 0;
      }
      @include mq-max($changePoint) {
        display: block;
        height: auto;
        margin-right: 0;
        border-top: 1px solid $gray;
        &:last-child{
          border-bottom: 1px solid $gray;
        }
      }
      a {
        @include mq-max($changePoint) {
          background-color: $black;
        }
      }
      &>a {
        text-decoration: none;
        color: $textColor;
        display: inline-block;
        height: 100%;
        // display: flex;
        // align-items: center;
        // height: 100%;
        padding: 30px 15px;
        @include mq-max($changePoint) {
          display: block;
          padding: 15px 10px;
        }
        &:hover {
          color: $keyColor;
        }
      }

      //アイコン
      &--inIcon {
        font-weight: bold;
        text-align: center;
        justify-content: center;
        font-size: 12px;
        min-width: 102px;
        line-height: 1;
      }
      &--icon {
        padding-bottom: 10px;
        text-align: center;
        display: block;
      }
      &.has-dropdown--gnavi {
        position: relative;
        flex-direction: column;
        &::after {
          content: '\f107';
          font-family: "Font Awesome 5 Free";
          font-weight: 600;
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          line-height: 1;
          font-size: 16px;
        }
        & a {
          padding-right: 20px;
        }
        .l-dropDown--gnavi {
          background-color: $white;
          display: none;
          &>li {
            a {
              width: 210px;
              display: block;
              border-bottom: 1px solid $gray;
              padding: 18px 0;
            }
          }
        }
        &.isDropdownOpen {
          .l-dropDown--gnavi {
            transition: all .2s ease;
            position: absolute;
            top: 88px;
            left: 0;
            display: block;
            padding: 0 18px;
          }
        }
      }

      //お問い合わせ
      &--contact {
        background-color: $keyColor;
        font-size: 12px;
        font-weight: bold;
        &>a {
          position: relative;
          height: 100%;
          color: $white;
          font-weight: bold;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &::before {
            display: block;
            content: '\f0e0';
            font-size: 32px;
            font-family: $FontAwesome;
            font-weight: 600;
          }
        }
      }

      //下層メニュー
      //&.has-dropdown {
      //  &>a {
      //    position: relative;
      //    &::after {
      //      @include mq-max($changePoint) {
      //        @include fa('f067');
      //        position: absolute;
      //        top: 50%;
      //        right: 16px;
      //        transform: translateY(-50%);
      //      }
      //    }
      //    &.has-dropdown {
      //      &::after {
      //        @include mq-max($changePoint) {
      //          content: '\f068';
      //        }
      //      }
      //    }
      //  }
      //}
    }
  }

  .c-list__item {
    &.has-dropdown {
      &>a {
        position: relative;
        &::after {
          @include mq-max(md) {
            @include fa('f067');
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
          }
        }
        &.has-dropdown {
          &::after {
            @include mq-max(md) {
              content: '\f068';
            }
          }
        }
      }
    }
  }
