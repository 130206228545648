//全体共通で使うCSS定義
html {
  &.open {
    overflow: hidden;
    .l-navigation__item--contact,
    .l-navigation__inner,
    .l-header__modules>.logo {
      display: none;
    }

    body {
      overflow: hidden;
    }
  }
}

body {
  color: $textColor;
  font-family: $sanserif;
  line-height: $lineHeight;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pwid";

  @if $is-fixed==true {
    margin-top: $headerHeight;

    @include mq-max($changePoint) {
      margin-top: $headerHeight-sp;
    }
  }
}


//  見出し
///////////////////////////////////////////////////////////////////////////////////////////////////////
h2,
h3,
h4,
h5 {
  line-height: 1.5;
}

h1 {
  font-size: 30px;

  @include mq-max(xs) {
    font-size: 24px;
  }
}

h2 {
  font-size: 28px;
  margin-bottom: 30px;

  @include mq-max(xs) {
    font-size: 22px;
    margin-bottom: 24px;
  }
}

h3 {
  font-size: 22px;
  margin-bottom: 15px;

  @include mq-max(xs) {
    font-size: 18px;
    margin-bottom: 12px;
  }
}

h4 {
  font-size: 21px;
  margin-bottom: 10px;

  @include mq-max(xs) {
    font-size: 18px;
  }
}

h5 {
  font-size: 18px;
  margin-bottom: 10px;

  @include mq-max(xs) {
    font-size: 16px;
  }
}

//  文章
///////////////////////////////////////////////////////////////////////////////////////////////////////
a {
  text-decoration: none;
  color: $keyColor;

  &[href^="javascript:void(0)"] {
    pointer-events: none;
  }

  &[target="_blank"] {
    &:after {
      @include fa('f35d');
      margin-left: 10px;
      -webkit-font-smoothing: antialiased;
    }
  }

  &[href$=".pdf"] {
    &:after {
      @include fa('f1c1');
      font-weight: 400;
      color: $red;
      margin-left: 10px;
      -webkit-font-smoothing: antialiased;
    }
  }

  &:not(.c-btn) {
    color: #000;
    transition: color $transition;
  }
}

p {
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 2;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-max(xs) {
    font-size: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.is-large {
    font-size: 18px;
  }
}

blockquote {}


//  強調
strong {
  font-weight: 600;
}

//  画像
figure {
  img {
    width: 100%;

    @include mq-max(sm) {
      width: 100%;
    }
  }
}
img {
  width: 100%;
}

// Lists
ul,
ol {
  list-style-type: none;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
//  電話番号CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////
[data-js="tel"] {
  &>a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

//アイコンの装飾
.ico_point {
  display: inline-block;
}

.ico_box {
  max-width: 50px;

  @media screen and (max-width: 1168px) {
    max-width: 45px;
  }

  @media screen and (max-width: 768px) {
    max-width: 45px;
  }

  img {
    width: 100%;
  }
}

//見出しの装飾
.ico-title-bar {
  padding: 0 0 0 25px;
  position: relative;
}

.ico-title-bar:before {
  content: '';
  display: block;
  background: url(/inc/image/common/ico_title_bar.png) no-repeat center/contain;
  width: 10px;
  height: 50px;
  position: absolute;
  left: 0%;
  z-index: 1;
}




.ico-title-car {
  padding: 0 0 0 57px;
  position: relative;
}

.ico-title-car:before {
  content: '';
  display: block;
  background: url(/inc/image/common/ico-title-car.png) no-repeat center/contain;
  width: 45px;
  height: 50px;
  position: absolute;
  left: 0%;
  z-index: -1;
}




.ico-title-tab {
  padding: 0 0 0 57px;
  position: relative;
}

.ico-title-tab:before {
  content: '';
  display: block;
  background: url(/inc/image/common/ico-title-tab.png) no-repeat center/contain;
  width: 45px;
  height: 50px;
  position: absolute;
  left: 0%;
  z-index: -1;
}




.ico-title-recreation {
  padding: 0 0 0 57px;
  position: relative;
}

.ico-title-recreation:before {
  content: '';
  display: block;
  background: url(/inc/image/common/ico-title-recreation.png) no-repeat center/contain;
  width: 45px;
  height: 50px;
  position: absolute;
  left: 0%;
  z-index: -1;
}




.ico-title-bonus {
  padding: 0 0 0 57px;
  position: relative;
}

.ico-title-bonus:before {
  content: '';
  display: block;
  background: url(/inc/image/common/ico-title-bonus.png) no-repeat center/contain;
  width: 45px;
  height: 50px;
  position: absolute;
  left: 0%;
  z-index: -1;
}




.ico-title-scholarship {
  padding: 0 0 0 57px;
  position: relative;
}

.ico-title-scholarship:before {
  content: '';
  display: block;
  background: url(/inc/image/common/ico-title-scholarship.png) no-repeat center/contain;
  width: 45px;
  height: 50px;
  position: absolute;
  left: 0%;
  z-index: -1;
}

//福利厚生のページ装飾（移行予定）
.p-welfare-border {
  border-bottom: 1px solid #CCCCCC;
  padding-bottom: 30px;
}

//下層ページのファーストビュー
.l-section__bg_blue {
  @media screen and (max-width: 768px) {
    padding-bottom: 0px !important;
  }
}

.bg_blue {
  padding-bottom: 80px;
}

.bg_blue::before {
  content: "";
  background-color: #0085CD;
  position: absolute;
  left: 0;
  bottom: 60px;
  height: 100%;
  width: 30%;
  z-index: -100;

  @media screen and (max-width: 768px) {
    bottom: 0px;
    top: -300px;
    height: 65%;
    width: 15%;
    z-index: -100;
  }


  @media screen and (max-width: 568px) {
    bottom: 0;
    top: -92px;
    height: 36%;
    width: 20%;
  }
}

//gナビの事業案内の余白
.p-pr9 {
  padding-right: 9px;
  @media screen and (max-width: 1024px) {
    padding-right: 0;
  }
}

//外部リンクへのアイコン
.p-link-ico:after{
    content: "\f35d" !important;
    color: $keyColor !important;
    margin-left: 10px;
    right: -21px !important;
    -webkit-font-smoothing:antialiased;
  }

//マイナビの導線
.p-mynavi-logo-box {
  max-width: 280px;
  margin: 0 auto;
  transition: all 0.2s ease;
  img {
    width: 100%;
  }
  a:after {
    display: none !important;
  }
  &:hover {
    transition: all 0.2s ease;
    opacity: 0.7;
  }
}
.mb40 {
  margin-bottom: 40px;
}
.mb60 {
  margin-bottom: 60px;
}
.mt60 {
  margin-top: 60px;
}