.l-sitemap {
  background-color: $white;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99;
  margin: 0;
  top: 0;
  right: 0;
  visibility: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  &.is-open {
    visibility:visible;
  }
  &__cvBtn {
    margin-bottom: 10px;
    .c-btn {
      //min-width: 245px;
      min-width: 222px;
      padding: 15px 30px 15px 15px;
      @include mq-max(xs) {
        width: 100%;
        padding: 15px 30px 15px 15px;
        min-width: initial;
      }
    }
  }
  &__container {
    margin: 0;
    padding-top: $headerHeight;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mq-max(md) {
      padding: 95px 30px 0;
    }
  }
  &__header {
    margin: 0;
  }
  &__thumbnail {
    height: 100vh;
    padding-bottom: 0;
    padding-left: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include mq-max(md) {
      display: none !important;
    }
  }
  &__logo {
    @include mq-max(xs) {
      display: none !important;
    }
    img {
      max-width: 145px;
    }
  }
  &__link {
    padding: 5px 0px;
    font-size: 18px;
    display: block;
    color: $white;
    font-weight: bold;
    &:hover {
      color: $keyColor;
    }
    @include mq-max(lg) {
      padding: 5px 0;
      font-size: 16px;
    }
  }
  &__main {
    margin-bottom: 0;
    @include mq-max(md) {
      margin-bottom: 30px;
    }
    .c-list {
      margin-bottom: 0;
      @include mq-max(md) {
        padding-bottom: 0;
      }
    }
  }
  &__footer {
    border-top: 1px solid $white;
  }
  &__snsBox {
    width: 50%;
    margin-right: 15px;
    margin-left: auto;
    @include mq-max(lg) {
      width: 80%;
      margin-right: 0;
    }
  }

}
.l-dropDown {
  //position: absolute;
  //top: $headerHeight - 30px; // headerの高さ - 30px
  visibility: hidden;
  opacity: 0;
  //height: 0;
  //transition: all .5s ease;
  //z-index: -1;
  overflow: hidden;
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    left: 0;
  }
  @include mq-max($changePoint) {
    position: relative;
    transition: none;
    top: auto;
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
  li {
    //border-bottom: 1px solid $gray;
    @include mq-max($changePoint) {
      border-bottom: none;
    }
    &:last-child {
      border: none;
    }
    a {
      //background-color: $white;
      //padding: 10px 15px;
      white-space: nowrap;
      display: block;
      text-decoration: none;
      color: #000;
      width: 100%;
      @include mq-max($changePoint) {
        line-height: 0;
        height: 0;
        padding: 0;
        opacity: 0;
        //transition: height $transition,
        //            opacity 0.4s,
        //            padding $transition,
        //            line-height $transition;
      }
      @include mq-max(sm) {
        transition: height $transition,
        opacity 0.4s,
        padding $transition,
        line-height $transition;
      }
    }
  }
}

// クリック時
.c-list__item {
  &.isDropdownOpen {
    .l-dropDown {
      opacity: 1;
      visibility: visible;
      top: auto;
      opacity: 1;
      display: block;
      a {
        //transition: color $transition,
        //            height $transition;
        &:hover {
          color: $keyColor;
        }
        //@include mq-max($changePoint) {
          opacity: 1;
          line-height: 1.5;
          height: 32px;
          //padding: 15px 10px 15px 25px;
          //overflow: hidden;
        //}
      }
    }
  }
}
