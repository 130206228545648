///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

.c-btn {
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding: 21px 55px 21px 31px;
  max-width: 100%;
  color: #fff;
  background-color: $keyColor;
  line-height: 1.25;
  border: 1px solid $keyColor;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
  transition: color $transition,
              background-color $transition;
              box-shadow: $box-shadow;
  @include mq-max(xs) {
    width: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: $keyColor;
    background-color: $white;
    &:after {
      right: 35px;
    }
  }
  &:after {
    @include fa('f054');
    position: absolute;
    top: 52%;
    right: 41px;
    transform: translateY(-50%);
    transition: right $transition;
  }

  //PDF
  &[href$=".pdf"] {
    &:after {
      right: 36px;
    }
    &:hover {
      &:after {
        right: 36px;
        color: $white;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &:after {
      color: $keyColor;
      right: 36px;
    }
    &:hover {
      &:after {
        right: 36px;
        color: $white;
      }
    }
  }

  //テキスト量で長さ可変
  &__inline {
    display: inline-block;
    width: auto;
    @include mq-max(xs) {
      width: 100%;
    }
  }

  //小さいボタン
  &__small {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  //ホバーで背景がスライド
  &__slide {
    position: relative;
    overflow: hidden;
    transition: all .4s ease;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: $keyColor;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) .4s;
    }
    &:hover {
      background-color: #fff;
      color: #fff;
      &:before {
        transform: translateX(0%);
      }
    }
    &>span {
      position: relative;
      z-index: 1;
    }
  }
}


.c-btn_01 {
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding: 21px 55px 21px 0px;
  max-width: 100%;
  color: #000;
  // background-color: #fff;
  line-height: 1.25;
  border: none;
  text-align: left;
  font-weight: 600;
  margin-bottom: 30px;
  transition: color $transition,
              background-color $transition;
              box-shadow: none;
  transition: all .4s ease;
   @media screen and (max-width: 940px){
    padding: 11px 60px 11px 0px;
    }
  //  @media screen and (max-width: 568px){
  //   padding: 10px;
  //    }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    opacity: 0.7;
    transition: all .4s ease;
    // color: $white;
    // background-color: $keyColor;
    &:after {
      right: -0px;
    }
  }
  &:after {
    // background: url('/inc/image/common/ico_btn.png')no-repeat center / cover;
    position: absolute;
    top: 52%;
    right: 10px;
    transform: translateY(-50%);
    transition: right $transition;

    content: url(/inc/image/common/ico_btn.png);
    vertical-align: middle;
    padding-right: 5px;
  }

  //PDF
  &[href$=".pdf"] {
    &:after {
      right: 36px;
    }
    &:hover {
      &:after {
        right: 36px;
        color: $white;
      }
    }
  }

  //外部リンク
  &[target="_blank"] {
    &:after {
      color: $keyColor;
      right: 36px;
    }
    &:hover {
      &:after {
        right: 36px;
        color: $white;
      }
    }
  }

  //テキスト量で長さ可変
  &__inline {
    display: inline-block;
    width: auto;
    @include mq-max(xs) {
      width: 100%;
    }
  }

  //小さいボタン
  &__small {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  //ホバーで背景がスライド
  &__slide {
    position: relative;
    overflow: hidden;
    transition: all .4s ease;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: $keyColor;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) .4s;
    }
    &:hover {
      background-color: #fff;
      color: #fff;
      &:before {
        transform: translateX(0%);
      }
    }
    &>span {
      position: relative;
      z-index: 1;
    }
  }
}

.c-serviceBtn {
  display: block;
  position: relative;
  overflow: hidden;
  &:hover {
    .c-serviceBtn__bg {
      transform: scale(1.1);
    }
  }
  &__num {
    display: block;
  }
  &__inner {
    padding: 150px 0;
    color: $white;
    @include mq-max(sm) {
      padding: 50px 0;
    }
  }
  &__bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s cubic-bezier(0, -0.075, 0.27, 0.94);
    z-index: -1;
  }
  &__title {
    font-weight: bold;
    font-size: 1.5em;
  }
}